<template>
  <div class="setBox">
    <div class="topBackImg">
      <div class="goBack" >
        <img src="../../image/my/g.png" alt="" @click="goBack()" />
      </div>
      <div class="information">
        <div class="hear">
          <img :src="user.headImg">
        </div>
        <div class="nameSchool">
          <div class="name">{{ user.nickName }}</div>
          <div class="school">{{ user.school }}</div>
        </div>
        <div class="rightBtm">
          <div @click="gochatPage(userId)">
            <img src="../../image/quanz/sx.png" alt="" />
          </div>
          <div>
            <img src="../../image/quanz/gz.png" alt="" v-if="isFollow == 0 || isFollow == 3"/>
            <img src="../../image/quanz/ygz.png" alt="" v-if="isFollow == 1"/>
            <img src="../../image/quanz/hxgz.png" alt="" v-if="isFollow == 2"/>
          </div>
        </div>
      </div>
      <div class="signature">
        {{ user.desc }}
      </div>
    </div>
    <div class="numBox">
      <div class="numSize">
        <div class="numStyle">{{ userStat.followNum }}</div>
        <div class="sizeStyle">关注</div>
      </div>
      <div class="numSize">
        <div class="numStyle">{{ userStat.fansNum }}</div>
        <div class="sizeStyle">粉丝</div>
      </div>
      <div class="numSize">
        <div class="numStyle">{{ userStat.likeNum }}</div>
        <div class="sizeStyle">获赞</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="navBox">
      <div
        v-for="item in navList"
        :key="item.id"
        :class="item.id === navActive ? 'activeStyle' : ''"
        @click="navEvent(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="container" v-if="navActive === 0">

      <div class="contentBox" v-for="item in trends" :key="item.id">
        <div class="contentImg" @click="goDetail(item.id)">
          <img :src="JSON.parse(item.imgs)[0]" alt="" v-if="item.imgs"/>
          <div class="contentSize">{{ item.content }}</div>
          <div class="likeNum" @click.stop="likeEvent()">
            <img src="../../image/xmrx/d.png" alt="" />
            {{ item.likeNum }}赞
          </div>
        </div>
      </div>
<!--      <div class="contentBox">-->
<!--        <div class="contentImg" @click="goDetail()">-->
<!--          <img src="../../image/2.png" alt="" />-->
<!--          <div class="contentSize">开启时髦异域之旅，跟着ETRO去旅行</div>-->
<!--          <div class="likeNum" @click.stop="likeEvent()">-->
<!--            <img src="../../image/xmrx/d1.png" alt="" />-->
<!--            2339赞-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="BasicInformation" v-if="navActive === 1">
      <div class="formBox">
        <van-form>
          <van-cell title="昵称" v-model="user.nickName" />
          <van-cell title="学校" v-model="user.school" />
          <van-cell title="地址" v-model="user.address" />
          <van-cell title="认证" v-model="user.auth" />
          <van-cell title="简介" v-model="user.desc" />
        </van-form>
      </div>
      <div class="btn" @click="goaddFriends()" v-if="!isFriend">加为好友</div>
      <div class="btn" v-if="isFriend">已添加好友</div>
      <!-- <div class="btn1">解除好友关系</div> -->
    </div>
  </div>
</template>

<script>
import { checkFollow,getUserStat } from "@/api/user";
import {listTrends} from "@/api/trends";
export default {
  data() {
    return {
      navList: [
        {
          id: 0,
          name: "圈子",
        },
        {
          id: 1,
          name: "基本信息",
        },
      ],
      user: {
        headImg: "",
        nickName: "",
        school: "",
        address: "",
        auth: "已认证",
        desc: "",
      },
      userStat: {
        followNum: 0,
        fansNum: 0,
        likeNum: 0,
      },
      userId: '',   // 当前显示用户id
      navActive: 0,
      isFollow: 0,
      isFriend: false,
      search: {
        userId: '',
        pageNum : 1,
        pageSize: 10
      },
      trends:[]
    };
  },
  mounted() {
    this.userId = this.$route.query.userId;
    let user = this.$store.state.user;
    if (!this.tim.isReady()) {
      if (user.circleAuth == 2) {
        // SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
        let onSdkReady = function(event) {
          this.getUser(this.userId + "")
          this.checkFriend()
        };
        this.tim.on(this.Tim.EVENT.SDK_READY, onSdkReady, this);
        //SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
        let onSdkNotReady = function(event) {
          this.imLogin();
        };
        this.tim.on(this.Tim.EVENT.SDK_NOT_READY, onSdkNotReady, this);
        this.imLogin();
      }

    } else {
      this.getUser(this.userId + "")
      // this.checkFollow();
      // this.getUserStat();
    }
    this.checkFollow();
    this.getUserStat();
    this.getUserTrends();
  },
  destroyed() {
    this.tim.logout();
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    navEvent(item) {
      this.navActive = item.id;
    },
    // 动态详情
    goDetail(id) {
      this.$router.push({ path: "/trendsDetail" , query: {id: id}});
    },
    likeEvent() {
      console.log("点赞");
    },
    // 加好友
    goaddFriends() {
      this.$router.push({ path: "/addFriends" , query: {"userId": this.userId}});
    },
    // 私信
    gochatPage() {
      this.$router.push({ path: "/chatPage", query: {"userId": this.userId} });
    },
    imLogin() {
      let user = this.$store.state.user;
      if (user.circleAuth == 2 && !this.tim.isReady()) {
        let loginPromise = this.tim.login({userID: user.id + "", userSig: user.imSig});
        loginPromise.then(function (imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
        }).catch(function (imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      }
    },
    // 查看用户信息
    getUser(id) {
      let promise = this.tim.getUserProfile({
        userIDList: [id] // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
      });
      let tis = this;
      promise.then(function(imResponse) {
        let userProfile = imResponse.data[0];
        tis.user.nickName = userProfile.nick;
        tis.user.address = userProfile.location;
        tis.user.desc = userProfile.selfSignature;
        tis.user.headImg = userProfile.avatar;
        let profileCustom = userProfile.profileCustomField;
        let schoolJoinTime;
        for (let index in profileCustom) {
          if (profileCustom[index].key === "Tag_Profile_Custom_School") {
            tis.user.school = profileCustom[index].value;
          }
          if (profileCustom[index].key === "Tag_Profile_Custom_STime") {
            schoolJoinTime = profileCustom[index].value;
          }
        }
      }).catch(function(imError) {
        console.warn('getUserProfile error:', imError); // 获取其他用户资料失败的相关信息
      });
    },
    // 检查是否关注
    checkFollow() {
      checkFollow(this.userId).then(res => {
        this.isFollow = res.data.data;
      })
    },
    // 用户状态
    getUserStat() {
      getUserStat(this.userId).then(res => {
        this.userStat.followNum = res.data.data.followNum
        this.userStat.likeNum = res.data.data.trendsLikeNum
        this.userStat.followNum = res.data.data.followNum

      })
    },
    // 检查是否好友
    checkFriend() {
      let tis = this;
      let promise = this.tim.checkFriend({
        userIDList: [this.userId + ""],
        type: this.Tim.TYPES.SNS_CHECK_TYPE_BOTH,
      });
      promise.then(function(imResponse) {
        const { successUserIDList, failureUserIDList } = imResponse.data;
        // 校验成功的 userIDList
        successUserIDList.forEach((item) => {
          const { userID, code, relation } = item; // 此时 code 始终为0
          // 单向校验好友关系时可能的结果有：
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_NO_RELATION A 的好友表中没有 B，
          // 但无法确定 B 的好友表中是否有 A
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_A_WITH_B A 的好友表中有 B，但无法确定 B 的好友表中是否有 A
          // 双向校验好友关系时可能的结果有：
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_NO_RELATION A 的好友表中没有 B，B 的好友表中也没有 A
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_A_WITH_B A 的好友表中有 B，但 B 的好友表中没有 A
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_B_WITH_A A 的好友表中没有 B，但 B 的好友表中有 A
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_BOTH_WAY A 的好友表中有 B，B 的好友表中也有 A
          if (relation === tis.Tim.TYPES.SNS_TYPE_BOTH_WAY) {
            tis.isFriend = true;
          }
        });
        // 校验失败的 userIDList
        failureUserIDList.forEach((item) => {
          const { userID, code, message } = item;
        });
      }).catch(function(imError) {
        console.warn('checkFriend error:', imError);
      });
    },
    // 查询用户的圈子动态
    getUserTrends() {
      this.search.userId = this.userId;
      listTrends(this.search).then(res => {
        this.trends = this.trends.concat(res.data.data.items);
      })
    }
  },
};
</script>

<style scoped>
.topBackImg {
  width: 750px;
  height: 476px;
  background-image: url("../../image/my/back.png");
  background-size: 100% 100%;
}
.goBack img {
  width: 40px;
  height: 40px;
  margin-top: 30px;
  margin-left: 32px;
}
.information {
  display: flex;
  align-items: center;
  margin-top: 44px;
  margin-left: 32px;
}
.hear {
  width: 120px;
  height: 120px;
  //background: red;
  border-radius: 50%;
  overflow: hidden;
}
.hear img {
  height: 100%;
}
.nameSchool {
  width: 300px;
  margin-left: 24px;
}
.name {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
}
.school {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 8px;
}
.rightBtm {
  display: flex;
}
.rightBtm div:nth-child(1) img {
  width: 96px;
  height: 56px;
}
.rightBtm div:nth-child(2) img {
  width: 120px;
  height: 56px;
  margin-left: 16px;
}
.signature {
  width: 454px;
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
  margin-left: 32px;
  margin-top: 24px;
  display: -webkit-box; /* Safari */
  -webkit-line-clamp: 1; /* Safari and Chrome */
  -webkit-box-orient: vertical; /* Safari and Chrome */
  overflow: hidden;
}
.numBox {
  height: 168px;
  width: 750px;
  background: #ffffff;
  margin-top: -106px;
  border-radius: 24px 24px 0px 0px;
  display: flex;

  align-items: center;
  justify-content: space-around;
}
.numSize {
  text-align: center;
}
.numStyle {
  font-size: 40px;
  font-weight: 400;
  color: #0b1526;
}
.sizeStyle {
  font-weight: 400;
  color: #97a4b4;
  font-size: 24px;
  margin-top: 8px;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.navBox {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 30px;
}
.navBox div {
  margin-left: 32px;
  margin-right: 16px;
}
.activeStyle {
  font-weight: 600;
  font-size: 32px;
  color: #0b1526;
}
.container {
  /* width: 686px; */
  /* 整个窗口的高 */
  /* flex: 1; */
  /* 多列显示 */
  columns: 2;
  /* overflow: auto; */
  padding-left: 32px;
  padding-right: 86px;
  box-sizing: border-box;
  margin-top: 32px;
  margin-bottom: 56px;
  /* margin-left: 32px; */
}
.contentBox {
  width: 332px;
}
.contentImg {
  /* width: 332px; */
  /* 不折列 */
  break-inside: avoid;
  position: relative;
}
.contentImg img {
  width: 100%;
  border-radius: 16px;
}
.contentSize {
  width: 332px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-bottom: 44px;
  display: -webkit-box; /* Safari */
  -webkit-line-clamp: 2; /* Safari and Chrome */
  -webkit-box-orient: vertical; /* Safari and Chrome */
  overflow: hidden;
}
.likeNum {
  position: absolute;
  bottom: 106px;
  right: 16px;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  //color: #ffffff;
}
.likeNum img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.formBox {
  /* border-bottom: 1px solid #f6f8fa; */
  margin-top: 48px;
}
.van-cell {
  line-height: 64px;
}

/deep/.van-cell__title {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
}
/deep/.van-cell__value {
  color: #0b1526;
}
.btn {
  width: 686px;
  height: 84px;
  background: #0957c3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  margin: 100px auto;
}

.btn1 {
  width: 686px;
  height: 84px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  border: 2px solid #fa2819;
  font-size: 32px;
  font-weight: 400;
  color: #fa2819;
  text-align: center;
  line-height: 84px;
  margin: 100px auto;
}
</style>
